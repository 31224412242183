(function () {
	var Store = function (options) {


		this.init();

	};

	let localStorageHander = {
		get: function (a) {
			return window.localStorage[a] ? localStorage[a] : localStorage.getItem(a);
		},
		set: function (a, v) {
			window.localStorage[a] = v;
			window.localStorage.setItem(a, v);
		},
		remove: function (a) {
			window.localStorage.removeItem(a);
		}
	};

	let sessionStorageHander = {
		get: function (a) {
			return window.sessionStorage[a] ? sessionStorage[a] : sessionStorage.getItem(a);
		},
		set: function (a, v) {
			window.sessionStorage[a] = v;
			window.sessionStorage.setItem(a, v);
		},
		remove: function (a) {
			window.sessionStorage.removeItem(a);
		}
	};

	let cookieHander = {
		get: function (key) {
			var encodedKey = encodeURIComponent(key);
			var cookies = document.cookie ? document.cookie.split(';') : [];
			// console.log(cookies)
			// retrieve last updated cookie first
			for (var i = cookies.length - 1, cookie; i >= 0; i--) {
				cookie = cookies[i].replace(/^\s*/, '');
				if (cookie.indexOf(encodedKey + '=') === 0)
					return decodeURIComponent(cookie.substring(encodedKey.length + 1, cookie.length));
			}
			return null;
		},
		set: function (a, v) {
			var cookie = encodeURIComponent(a) + '=' + encodeURIComponent(v);
			// cookie += '; SameSite=none';
			// cookie += '; Secure';
			document.cookie = cookie + '; path=/';
		},
		remove: function (key) {
			// remove cookie from main domain
			this.set(key, '', {expireDays: -1});
			// remove cookie from upper domains
			var domainParts = document.domain.split('.');
			for (var i = domainParts.length; i > 1; i--) {
				this.set(key, '', {expireDays: -1, domain: '.' + domainParts.slice(-i).join('.')});
			}
		}
	};

	function findMost2(arr) {
		//  定义一个空对象存储数据
		var h = {};
		//    假设频率高的数出现次数初始为0
		var maxNum = 0;
		//    清空频率高的数
		var maxEle = null;
		//    对数组从左往右遍历
		for (var i = 0; i < arr.length; i++) {
			//    对数组的每一个数据进行存储存于a
			var a = arr[i];
			// 判断存储的数字是否为默认值， 存在 对属性的值进行+1，不存在 往对象中重新添加属性赋值为1；
			h[a] === undefined ? h[a] = 1 : (h[a]++);
			// 判断存入的数据是否大于初始的频率高数，如果满足将存入高频数和出现次数的覆盖前一次的。
			if (h[a] > maxNum) {
				maxEle = a;
				maxNum = h[a];
			}
		}
		return maxEle;
	}

	let memoryHander = {
		_hash: {},
		get: function (a) {
			return this._hash[a]
		},
		set: function (a, v) {
			this._hash[a] = v
		},
		remove: function (a) {
			delete this._hash[a]
		}
	}

	Store.prototype = {

		/**
		 * 初始化
		 */
		init: function () {

		},

		set: function (key, value) {
			localStorageHander.set(key,value)
			sessionStorageHander.set(key,value)
			memoryHander.set(key,value)
			cookieHander.set(key,value)
		},

		get: function (key) {
			let valueArr = [
				localStorageHander.get(key),
				sessionStorageHander.get(key),
				memoryHander.get(key),
				cookieHander.get(key)
			]
			let value
			if(value = memoryHander.get(key)){
				this.set(key,value)
				return value
			}

			value = findMost2(valueArr)
			value && this.set(key,value)
			console.log(value)
			return value
		},

		remove: function (key) {
			localStorageHander.remove(key)
			sessionStorageHander.remove(key)
			memoryHander.remove(key)
			cookieHander.remove(key)
		}
	};

	Store.defaults = {
	}

	window.Store = function (options) {

		options = options ? options : {};

		return new Store(options);
	}
})()