<template>
    <!-- 路由匹配到的组件将渲染在这里 -->
    <router-view></router-view>
</template>

<script>

export default {
    name: 'App',
    components: {}
}
</script>

<style>

#app {

    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
* {
    margin: 0;
    padding: 0;
    /*使 padding border 都计算到width 内*/
    box-sizing: border-box;
    -moz-box-sizing: border-box; /* Firefox */
    -webkit-box-sizing: border-box; /* Safari */
}
</style>
