import {createApp} from 'vue'
import App from './App.vue'
import routes from "./router.js"
import Antd from 'ant-design-vue';
import "./storedata.js"
// 引入组件库的少量全局样式变量
import 'ant-design-vue/dist/antd.css';
// 预览组件以及样式
import VMdPreview from '@kangc/v-md-editor/lib/preview';
import '@kangc/v-md-editor/lib/style/preview.css';
// VuePress主题以及样式（这里也可以选择github主题）
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
import '@kangc/v-md-editor/lib/theme/style/vuepress.css';
// import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
// import '@kangc/v-md-editor/lib/theme/style/github.css';
// Prism
import Prism from 'prismjs';
// 代码高亮
import 'prismjs/components/prism-json';



// 选择使用主题
VMdPreview.use(vuepressTheme, {
	Prism,
});

createApp(App)
	.use(routes)
	.use(Antd)
	.use(VMdPreview)
	.mount('#app')
