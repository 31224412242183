<template>
    <h1>login</h1>
</template>

<script>
export default {
    name: "Login"
}
</script>

<style scoped>

</style>