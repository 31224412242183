import { createRouter, createWebHashHistory } from 'vue-router'
import Index from "./pages/Index.vue"
import Login from "./pages/Login.vue"
import Image from "./pages/Image.vue"

const routes = [
	{path: '/', component: Index},
	{path: '/login', component: Login},
	{path: '/image', component: Image},
];

const router = createRouter({
	history:createWebHashHistory(),
	routes
});

export default router