<template>
    <div class="content">
        <div class="ad3" v-if="!isMobile">
            <div style="margin: auto;width: 336px;padding-top: 50px;: ">
                <UnionAd id="u6913468" style="margin-bottom: 20px;"></UnionAd>
                <UnionAd id="u6913469"></UnionAd>
            </div>
        </div>
        <div class="box" id="message-list" :style="{width:isMobile ? '100%': '760px'}">
            <div class="ad1" :style="{height:isMobile ? '60px':'50px','paddingTop':'10px','paddingBottom':'10px','textAlign':'center'}">
                <a-button href="/" style="margin-right: 10px;">文本</a-button>
                <a-button href="/#image" type="primary">图片</a-button>
            </div>
            <div class="message-box" ref="message-list">
                <a-comment v-for="(item, index) in list" :key="index">
                    <template #avatar>
                        <img v-if="item.user == 0" src="../assets/toux.png" alt="Han Solo"/>
                        <img v-if="item.user != 0" src="../assets/toux1.webp" alt="Han Solo"/>
                    </template>
                    <template #content>
                        <a-spin v-if="!item.msg" style="position: relative;top:2px;"/>
                        <a-image
                            v-else-if="item.isImg===1"
                            :width="200"
                            :height="200"
                            :src="item.msg"
                        />
                        <v-md-preview v-else :text="item.msg"></v-md-preview>
                    </template>
                </a-comment>
            </div>
            <div class="send">
                <a-input-search
                    placeholder="内容"
                    enter-button="发送"
                    size="large"
                    v-model:value="message"
                    :loading="loading"
                    @search="sendMsg"
                />
            </div>
            <div class="ad2">
                <UnionAd v-if="!isMobile" id="u6913467"></UnionAd>
                <UnionAd v-else id="u6913475"></UnionAd>
            </div>
        </div>
        <div class="ad4" v-if="!isMobile">
            <div style="margin: auto;width: 336px;padding-top: 50px;">
                <UnionAd id="u6913470" style="margin-bottom: 20px;"></UnionAd>
                <UnionAd id="u6913471"></UnionAd>
            </div>
        </div>
    </div>
</template>

<script>
import {message} from 'ant-design-vue';
import {createUnionAdComponent} from 'union-ad-vue';
import {h} from 'vue';

export default {
    name: "Index",
    components: {
        UnionAd: createUnionAdComponent(h)
    },
    data() {
        return {
            message: "",
            websock: null,
            list: [],
            tmp: {},
            loading: false,
            msgIndex: 0,
            loadingMessageId: '',
            store: null,
            isMobile: false,
        }
    },

    created() {

    },
    mounted() {
        this.initWebSocket();
        this.store = new window.Store();
        this.getId()
        this.history()
        if (window.innerWidth < 1000) {
            this.isMobile = true;
        }
    },

    methods: {
        history() {
            let that = this;
            fetch(process.env.VUE_APP_HTTP_URL + '/index/getImageHistory?type=1&userId=' + this.getId()).then(function (data) {
                // text()方法属于fetchAPI的一部分，它返回一个Promise实例对象，用于获取后台返回的数据
                return data.text();
            }).then(function (data) {
                //   在这个then里面我们能拿到最终的数据
                data = JSON.parse(data)
                if (data.code == 200) {
                    for (let i in data.data) {
                        that.list.push({"user": data.data[i]['role'] == 'user' ? 1 : 0,'isImg':data.data[i]['isImg'], "msg": data.data[i]['content']})
                    }
                    setTimeout(() => {
                        that.scrollFooter('message-list')
                    }, 16)
                }
            })
        },
        initStore() {
            let id = this.algorithm();
            this.store.set("deviceid", id);
            return id;
        },
        getId() {
            if (!this.store) {
                this.store = new window.Store();
            }
            let deviceid = this.store.get("deviceid");
            if (!deviceid) {
                return this.initStore();
            }
            return deviceid;
        },
        sendMsg() {
            if (!this.message) {
                return;
            }

            if (this.message.length > 200) {
                message.error('输入内容过长，最多200个字')
                return;
            }

            if (this.loading) {
                return;
            }

            const vm = this;
            this.list.push({"user": 1, "msg": this.message})
            this.websocketSend({"msg": this.message.toString(), 'type': 1})
            this.message = '';
            setTimeout(() => {
                vm.scrollFooter('message-list')
            }, 100)
        },

        initWebSocket() { //初始化weosocket
            console.log(process.env)
            const wsuri = process.env.VUE_APP_WS_URL + '?userId=' + this.getId();
            // const wsuri = 'ws://127.0.0.1:9501?userId=' + this.getId();
            //连接服务端
            this.websock = new WebSocket(wsuri);
            //指定事件回调
            this.websock.onmessage = this.websocketOnMessage;
            this.websock.onopen = this.websocketOnOpen;
            this.websock.onerror = this.websocketOnError;
            this.websock.onclose = this.websocketClose;
        },

        websocketOnOpen() { //连接建立之后执行send方法发送数据
            this.websocketSend({'type': 100, 'msg': 'init'});
            //连接后,定时发送,否则不段时间不通信会自动断连(时间长短一般是服务端指定的)
            var that = this;
            setInterval(function () {
                that.websocketSend({'type': 101, 'msg': 'ping'})
            }, 15000);
        },

        websocketOnError() {//连接建立失败重连
            this.initWebSocket();
        },

        /**
         * 接收数据
         * @param e
         */
        websocketOnMessage(e) { //数据接收
            if (!e.data) return;
            let response = JSON.parse(e.data);
            // console.log(response);
            if (response['code'] == '200') {
                let data = response.data
                if (data.type == 1) {
                    if (this.tmp[data.messageId]) {
                        if (data.isEnd == 0) {
                            this.list[this.tmp[data.messageId]]['msg'] += data.msg
                        } else {
                            this.list[this.tmp[data.messageId]]['msg'] += data.msg
                            this.list[this.tmp[data.messageId]]['isImg'] = data.hasOwnProperty('isImg') ? data.isImg : 0
                            this.list[this.tmp[data.messageId]]['isEnd'] = 1;
                            delete this.tmp[data.messageId]
                        }
                    } else {
                        data.user = 0;
                        this.list.push(data)
                        if (data.isEnd == 0) {
                            this.tmp[data.messageId] = this.list.length - 1;
                        } else {
                            console.log(data)
                        }
                        console.log(data,this.list)
                    }

                    if (data.isEnd == 1) {
                        if (this.loadingMessageId && data.messageId == this.loadingMessageId) {
                            this.loading = false;
                            this.loadingMessageId = '';
                        }
                    }
                    const vm = this;
                    setTimeout(() => {
                        vm.scrollFooter('message-list')
                    }, 200)
                }
            } else {
                message.error(response.msg)
            }
        },

        /**
         * 发送信息
         * @param Data
         */
        websocketSend(data) {//数据发送
            if (data.type == 1) {
                this.loading = true
                this.msgIndex++;
                data.messageId = this.loadingMessageId = this.randomString(9) + '_' + this.msgIndex;
                this.list.push({msg: '', user: 0})
                this.tmp[data.messageId] = this.list.length - 1;
            }

            this.websock.send(JSON.stringify({"c": "image", 'm': "q", params: data}));
        },

        websocketClose(e) {  //关闭
            console.log('断开连接', e);
        },

        /**
         * 滚动到底部
         * @param name
         */
        scrollFooter(name) {
            let $el = this.$refs[name];
            if ($el) {
                this.$nextTick(() => {
                    $el.scrollTop = $el.scrollHeight
                })
            }
        },

        /**
         * 获取随机字符
         * @param len
         * @returns {string}
         */
        randomString(len) {
            len = len || 32;
            var $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
            /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
            var maxPos = $chars.length;
            var pwd = '';
            let i;
            for (i = 0; i < len; i++) {
                pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
            }

            return pwd;
        },

        /**
         *
         * @returns {string}
         */
        algorithm() {
            let abc = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'g', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
            let [max, min] = [Math.floor(Math.random() * (10 - 7 + 1) + 1), Math.floor(Math.random() * (17 - 10 + 1) + 17)];
            abc = abc.sort(() => 0.4 - Math.random()).slice(max, min).slice(0, 8).join("");
            var a = new Date().getTime() + abc;
            return a
        }
    }
}
</script>

<style>
body {
    color-scheme: dark;
}

.content {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
}

.ad4, .ad3 {
    height: 100%;
    flex: 1;
    width: auto;
//background-color: red; padding: 10px;
}


.box {
    display: flex;
    height: 100%;
    flex-direction: column;
    max-width: 760px;
}

.ad1, .ad2 {
    height: 100px;
//background-color: gray;

}

.box .message-box {
    flex: 1;
    border: 1px solid #ebedf0;
    border-bottom: none;
    padding: 10px;
    height: auto;
    overflow-y: scroll;
}

.box .message-box .ant-comment-inner {
    padding: 8px 0;
}

.box .send {

}

#message-list .vuepress-markdown-body {
    padding: 0;
    font-size: 16px;
}
</style>